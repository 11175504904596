// import React from "react"; // Strict mode disabled by default because of OSD.
import ReactDOM from "react-dom/client";
import {App} from "./components/App";
import {BrowserRouter} from "react-router-dom";
import {UserProvider} from "./context/UserProvider";
import "./styles/styles.scss";
import "tippy.js/dist/tippy.css";
import "react-day-picker/style.css";
import "./styles/react-day-picker-override.css";
import {ProjectsProvider} from "./context/ProjectsProvider";
import {LayoutProvider} from "./context/LayoutProvider";
import {SelectedTimestampProvider} from "./context/SelectedTimestampProvider";
import {CompareImagesProvider} from "./context/CompareImagesProvider";
import {CalendarDateTimeProvider} from "./context/CalendarDateTimeProvider";
import './i18n';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <UserProvider>
    {/* <React.StrictMode> */}
    <LayoutProvider>
      <ProjectsProvider>
        <SelectedTimestampProvider>
          <CalendarDateTimeProvider>
            <BrowserRouter>
              <CompareImagesProvider>
                <App />
              </CompareImagesProvider>
            </BrowserRouter>
          </CalendarDateTimeProvider>
        </SelectedTimestampProvider>
      </ProjectsProvider>
    </LayoutProvider>
    {/* </React.StrictMode> */}
  </UserProvider>,
);
