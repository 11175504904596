import en from "date-fns/locale/en-GB"
import { DateRange, DayPicker, getDefaultClassNames, OnSelectHandler } from "react-day-picker";
import React, { useContext, useState } from "react";
import { CalendarDateTimeContext } from "../../../context/CalendarDateTimeProvider";
import { Popover } from "@headlessui/react";
import { sub } from "date-fns";
import { Icon } from "../Icon/Icon";
import Slider from "rc-slider";
import classNames from "classnames";
import { useWindowWidth } from '@react-hook/window-size';
import { useTranslation } from "react-i18next";
import { lngs } from "../../layout/LanguageMenu";

type Props = {
  startDate?: Date;
  endDate?: Date;
  showTime?: boolean;
};

export const Calendar = (props: Props) => {
  const screenWidth = useWindowWidth()
  const [range, setRange] = useState<DateRange | undefined>();
  const { setStartDate, setEndDate, setStartTime, setEndTime, startTime, endTime } = useContext(CalendarDateTimeContext);
  const [timeRangePosition, setTimeRangePosition] = useState<number[]>([startTime, endTime]);
  const { t, i18n } = useTranslation();

  // Removes timezone from chosen dates to get UTC date objects
  const onSelect: OnSelectHandler<DateRange | undefined> = (range) => {
    // Set start- and end-date for use in stacks
    const minutes = new Date().getTimezoneOffset();
    const startTime = sub(range?.from as Date, { minutes });
    const endTime = sub(range?.to as Date, { minutes });
    endTime.setUTCHours(23, 59, 59);

    setStartDate(range?.from ? startTime : null);
    setEndDate(range?.to ? endTime : null);

    setRange(range);
  };

  const reset = () => {
    setRange(undefined);

    setStartDate(null);
    setEndDate(null);

    setStartTime(0);
    setEndTime(24);

    setTimeRangePosition([0, 24]);
  };

  const onChangeTime = (times: number[]) => {
    setStartTime(times[0]);
    setEndTime(times[1]);
  };

  const onChangeTimeValues = (times: number[]) => {
    setTimeRangePosition(times);
  };

  const defaultClassNames = getDefaultClassNames();

  return (
    <Popover as={React.Fragment}>
      <Popover.Button as={React.Fragment}>
        <div className={"absolute right-11 top-0 md:top-8 z-10"}>
          <button className="m-1 block rounded-md p-1 bg-panterra-800 hover:bg-panterra-700">
            <Icon iconClassName={"text-white w-7 h-7"} icon={"MagnifyingGlassIcon"}></Icon>
          </button>
        </div>
      </Popover.Button>
      <Popover.Panel className={"absolute right-14 top-24 z-10 rounded bg-panterra-50 text-panterra-900"}>
        <div>
          <DayPicker
            locale={i18n.resolvedLanguage ? lngs[i18n.resolvedLanguage].calendarLocale : en}
            mode="range"
            numberOfMonths={screenWidth >= 768 ? 2 : 1}
            min={0}
            startMonth={props.startDate}
            endMonth={props.endDate}
            disabled={[{ before: props.startDate ?? new Date(1980, 0, 0) }, { after: props.endDate ?? new Date(2099, 0, 0) }]}
            selected={range}
            onSelect={onSelect}
            captionLayout={screenWidth >= 768 ? "dropdown" : "label"}
            classNames={{
              root: `${defaultClassNames.root} p-4`,
            }}
          />

          <div className={classNames("m-8 px-8", { hidden: !props.showTime })}>
            <Slider
              range
              min={0}
              max={24}
              marks={[0, 3, 6, 9, 12, 15, 18, 21, 24].reduce((obj, item) => {
                return {
                  ...obj,
                  [item]: `${item}:00`,
                };
              }, {})}
              step={1}
              value={[timeRangePosition[0], timeRangePosition[1]]}
              allowCross={false}
              onChange={(value) => onChangeTimeValues(value as number[])}
              onAfterChange={(value) => onChangeTime(value as number[])}
              pushable
              draggableTrack
              trackStyle={{ backgroundColor: "#394245" }}
              dotStyle={{ backgroundColor: "#EEEEEE", borderColor: "#394245" }}
              handleStyle={{ background: "#EEEEEE", borderColor: "#394245", opacity: 1 }}
            />
          </div>

          <button onClick={() => reset()} className={"mx-auto block pb-4"}>
            {t('resetCalendar')}
          </button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
